// components/SavedSearchesModal.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
    DialogTrigger,
    DialogClose
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { getIdToken } from '../../services/userDataService'; // You'll need this
import { useNotification } from '../../components/useNotification'; // And this
import { Skeleton } from "../../components/ui/skeleton";
import { ScrollArea } from "../../components/ui/scroll-area"

// Receive setResults and setIsDescriptionSubmitted as props
const SavedSearchesModal = ({ userId, setResults, setIsDescriptionSubmitted }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searches, setSearches] = useState([]);
    const [loading, setLoading] = useState(false);
    const { addNotification } = useNotification();

    // Fetch saved searches when the modal opens
    useEffect(() => {
        const fetchSearches = async () => {
            if (!isOpen || !userId) return;

            setLoading(true);
            try {
                const token = await getIdToken();  // Get ID token
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/recruit-ai/fetchSavedTalentSearches`, { // Your API endpoint
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': `Bearer ${token}`, // Include if your API needs auth
                    },
                    body: JSON.stringify({ userId: userId }),
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch searches: ${response.status}`);
                }

                const data = await response.json();
                if (data && data.searches) {
                    setSearches(data.searches);
                } else {
                    setSearches([]);
                    addNotification("No searches found or invalid response format.", 'info');
                }
            } catch (error) {
                console.error('Error fetching searches:', error);
                addNotification(`Error fetching searches: ${error.message}`, 'error');
                setSearches([]);
            } finally {
                setLoading(false);
            }
        };

        fetchSearches();
    }, [isOpen, userId, addNotification]); // Dependencies: isOpen, userId, addNotification

    const handleSearchSelect = (search) => {
        // 1. Update the results in the parent component:
        setResults({
            job_title: search.jobTitle,  // Pass the job title
            top_candidates: search.topCandidates, // Pass the candidate data
        });

        // 2.  Tell the parent to switch to the results view:
        setIsDescriptionSubmitted(false);

        // 3. Close the modal:
        setIsOpen(false);
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="outline" className='rounded-xl'>Load Saved Searches</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px] rounded-xl">
                <DialogHeader>
                    <DialogTitle>Saved Searches</DialogTitle>
                    <DialogDescription>
                        Select a previous search to load.
                    </DialogDescription>
                </DialogHeader>
                <ScrollArea className="h-[40vh] rounded-md border p-4">
                    <div className="space-y-4">
                        {loading ? (
                            <>
                                <Skeleton className="w-full h-8 rounded-md" />
                                <Skeleton className="w-full h-8 rounded-md" />
                                <Skeleton className="w-full h-8 rounded-md" />
                                <Skeleton className="w-full h-8 rounded-md" />
                                <Skeleton className="w-full h-8 rounded-md" />
                            </>
                        ) : searches.length > 0 ? (
                            searches.map((search) => (
                                <div
                                    key={search.searchId}
                                    className="p-2 border rounded-md hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleSearchSelect(search)} // Call handleSearchSelect on click
                                >
                                    <div className="font-semibold">{search.jobTitle}</div>
                                    <div className="text-sm text-gray-500">
                                        {new Date(search.createdAt).toLocaleDateString()}
                                    </div>
                                    {/* Removed redundant button, click on the div now */}
                                </div>
                            ))
                        ) : (
                            <div>No saved searches found.</div>
                        )}
                    </div>
                </ScrollArea>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">Close</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default SavedSearchesModal;