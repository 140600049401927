import React, { useState } from 'react';
// import Sidebar from './Sidebar'; // Remove Sidebar import
import JobDescriptionUpload from './jobDescriptionUpload'; // Keep these imports for content areas
import ResumeUpload from './resumeUpload';
import JobOpeningUploadForm from './JobOpeningUploadForm';
import Candidates from './candidates';
// import SavedSearches from './SavedSearches'; // Placeholder components
// import SavedJobs from './SavedJobs';
// import ViewJobOpenings from './ViewJobOpenings';
import CandidateUploadForm from './CandidateUploadForm'; // You'll create this next for Inverse RecruitAI
import SavedSearchesModal from './SavedSearchesModal'; // Import SavedSearchesModal

import { TooltipProvider } from "../../components/ui/tooltip";
import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../components/ui/tabs" // Import Tabs components
import { Button } from "../../components/ui/button" // Import Button
import SavedJobs from './SavedJobs';

// Placeholder Components (replace with your actual components later)
const CandidateDetailsForm = () => <div>CandidateDetailsForm Placeholder</div>;
const JobList = () => <div>JobList Placeholder</div>;
const JobDetailsForm = () => <div>JobDetailsForm Placeholder</div>;
const TalentList = () => <div>TalentList Placeholder</div>;


const RecruitAIDashboard = ({ userData }) => {
  const [activeTab, setActiveTab] = useState("jobSearch"); // State for active tab: "jobSearch" | "talentSearch"
  const [results, setResults] = useState(null); // Add state for results -- IMPORTANT
  const [isDescriptionSubmitted, setIsDescriptionSubmitted] = useState(true); // CORRECT: Start with the form


  return (
    <TooltipProvider>
      <div className="p-6 w-full h-full flex items-center justify-center"> {/* Flex container for vertical centering */}
        <Card className="max-w-screen-2xl h-full w-full"> {/* Main Card Container */}
          <CardContent className="p-6">
            <Tabs value={activeTab} className="space-y-4 h-full"> {/* **Tabs component wraps TabsList and TabsContent** */}
              <div className='flex flex-row items-center justify-between'> {/* Flex container for title and tabs */}
                <div className='flex flex-row items-center'> {/* Flex container for title and saved searches button */}
                  <CardTitle className="text-2xl m-0">RecruitAI</CardTitle>
                  <TabsList className="ml-12 rounded-xl"> {/* **TabsList MOVED INSIDE Tabs, added mb-4 for spacing** */}
                    <TabsTrigger className="rounded-xl" value="jobSearch" onClick={() => setActiveTab("jobSearch")}>Job Search</TabsTrigger>
                    <TabsTrigger className="rounded-xl" value="talentSearch" onClick={() => setActiveTab("talentSearch")}>Talent Search</TabsTrigger>
                  </TabsList>
                </div>
                <SavedSearchesModal
                  userId={userData.user_data.user_id}
                  setResults={setResults} // PASS THE PROPS!
                  setIsDescriptionSubmitted={setIsDescriptionSubmitted} // PASS THE PROPS!
                /> {/* Saved Searches Modal */}
              </div>
              <TabsContent value="jobSearch">
                <div className="grid grid-cols-2 gap-6"> {/* 2-column grid for Job Search tab */}
                  <div>
                    <CandidateUploadForm userData={userData} />
                  </div>
                  <div>
                    <SavedJobs userData={userData} /> {/* Placeholder saved jobs list */}
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="talentSearch">
                <div className="grid grid-cols-2 gap-6"> {/* 2-column grid for Talent Search tab */}
                  <div>
                    <Card className="h-full">
                      <CardHeader>
                        <CardTitle>Talent Search</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <JobDescriptionUpload
                          userData={userData}
                          setResults={setResults}                          setIsDescriptionSubmitted={setIsDescriptionSubmitted}
                          results={results}
                          isDescriptionSubmitted={isDescriptionSubmitted}
                        />
                      </CardContent>
                    </Card>
                  </div>
                  <div>
                    <Card className="h-full">
                      <CardContent>

                        <Candidates userData={userData} /> {/* Placeholder talent list */}
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </TooltipProvider>
  );
};

export default RecruitAIDashboard;