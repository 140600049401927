// src/modules/reportBuilder/ReportBuilder.js
import React, { useContext, useState, useEffect } from "react";
import { ReportContext } from "../../context/ReportContext";
import axios from "axios";
import { Document, Paragraph, TextRun, Packer, HeadingLevel } from "docx";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";
import { Checkbox } from "../../components/ui/checkbox";
import { Textarea } from "../../components/ui/textarea";

/**
 * ReportBuilder Component
 *
 * Provides a UI for building custom reports by specifying grouping columns,
 * aggregation columns, aggregation functions, and a report objective.  The component also fetches
 * the generated report text and provides options to download the report as either a docx file, or a text file.
 *
 * @param {object} props - The component's props.
 * @param {object} props.userData - User data object.
 * @returns {JSX.Element} The ReportBuilder JSX element.
 */
function ReportBuilder({ userData }) {
     /**
      * @type {[File|null, function]} selectedFile - Represents the file uploaded by the user.
      */
    const [selectedFile, setSelectedFile] = useState(null);
    /**
      * @type {[Array<string>, function]} columns - An array of column header strings that are retrieved from the uploaded file.
      */
    const [columns, setColumns] = useState([]);
      /**
      * @type {[boolean, function]} isLoading - Represents the loading state of the report generation process.
      */
    const [isLoading, setIsLoading] = useState(false);
      /**
      * @type {[string|null, function]} error - Represents the error message if something goes wrong during the report generation.
      */
    const [error, setError] = useState(null);
    /**
     * @type {[Array<string>, function]} groupingColumns - An array of column names that are used for grouping in the report.
     */
    const [groupingColumns, setGroupingColumns] = useState([]);
       /**
     * @type {[Array<string>, function]} aggregationColumns - An array of column names that are used for aggregations in the report.
     */
    const [aggregationColumns, setAggregationColumns] = useState([]);
    /**
      * @type {[object, function]} aggregationFunctions - An object that stores aggregation functions for each aggregation column.
      */
    const [aggregationFunctions, setAggregationFunctions] = useState({});
     /**
      * @type {[object, function]} aggregationLabels - An object that stores custom labels for aggregation columns.
      */
    const [aggregationLabels, setAggregationLabels] = useState({});
       /**
     * @type {[string, function]} reportObjective - The user specified objective of the report.
     */
    const [reportObjective, setReportObjective] = useState("");
      /**
     * @type {[string|null, function]} fileId - ID of the uploaded file, as returned by the backend.
     */
    const [fileId, setFileId] = useState(null);
    /**
      * @type {[string|null, function]} reportId - ID of the report, as returned by the backend.
      */
    const [reportId, setReportId] = useState(null);
    /**
    * @type {object} shouldRefresh - Represents if the report should be refreshed
    * @type {function} clearReportRefresh - Function that sets shouldRefresh to false
    */
    const { shouldRefresh, clearReportRefresh } = useContext(ReportContext);
    /**
     * @type {[string, function]} status - Represents the current status of the report generation.
     */
    const [status, setStatus] = useState("ready");
       /**
     * @type {[string, function]} reportText - Represents the text of the generated report.
     */
    const [reportText, setReportText] = useState(""); // To store the fetched report text

    /**
     * Handles changes to the grouping columns checkbox selection
     *
     * @param {object} event - The checkbox change event, containing the column that is checked.
     */
    const handleGroupingColumnChange = (event) => {
        const column = event.target.value;
        if (event.target.checked) {
            setGroupingColumns([...groupingColumns, column]);
            console.log("Adding column to grouping:", column);
        } else {
            setGroupingColumns(groupingColumns.filter((c) => c !== column));
        }
    };

    /**
     * Handles the change to the report objective text area.
     *
     * @param {object} event - The text area change event.
     */
    const handleObjectiveChange = (event) => {
        setReportObjective(event.target.value);
    };

    /**
     * Fetches the generated report from the backend by using the report ID.
     * Updates report text state and sets status.
     */
    const fetchReport = async () => {
        console.log("Fetching report data...");
        setIsLoading(true);
        try {
            const response = await axios.get(
                `https://nyokug3qbj.execute-api.ca-central-1.amazonaws.com/dev/get-report?reportId=${reportId}&orgId=${userData.user_data.org_id}`
            );
            if (response.status !== 200) {
                throw new Error("Failed to fetch report data.");
            }
            setReportText(response.data.reportText);
            setStatus("report_fetched");
        } catch (err) {
            console.error("Error fetching report:", err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    /**
    * useEffect hook to check for refresh requests. Fetches report data
    * when `shouldRefresh` state is true.
    */
    useEffect(() => {
        if (shouldRefresh && reportId) {
            // Only fetch if reportId is available
            console.log("Fetching report data...");
            fetchReport()
                .then(() => {
                    clearReportRefresh();
                })
                .catch((err) => console.error(err));
        }
    }, [shouldRefresh, clearReportRefresh, reportId, userData]);

    /**
    * Handles changes to the selected aggregation columns checkboxes.
    *
    * @param {object} event - The input change event object.
    */
    const handleAggregationColumnChange = (event) => {
        const column = event.target.value;
        if (event.target.checked) {
            console.log("Adding column to aggregation:", column);
            setAggregationColumns([...aggregationColumns, column]);
        } else {
            setAggregationColumns(aggregationColumns.filter((c) => c !== column));
            console.log("Adding column to aggregation:", column);
            setAggregationFunctions((prevFunctions) => {
                const updatedFunctions = { ...prevFunctions };
                delete updatedFunctions[column];
                return updatedFunctions;
            });
        }
    };

    /**
    * Handles changes to the aggregation function for a specific column.
    *
    * @param {string} column - The name of the column that the aggregation is applied to.
    * @param {string} func - The aggregation function to use.
    */
    const handleAggregationFunctionChange = (column, func) => {
        setAggregationFunctions({ ...aggregationFunctions, [column]: func });
    };

     /**
     * Handles the change to the labels of aggregation columns.
     *
     * @param {string} column - The name of the column being updated.
     * @param {string} label - The new label for the column.
     */
    const handleAggregationLabelChange = (column, label) => {
        setAggregationLabels({ ...aggregationLabels, [column]: label });
    };

    /**
   * Handles changes to the file upload input, setting the selected file to state.
   *
   * @param {object} event - The input change event object.
   */
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log("File details:", file);
        setSelectedFile(file);
    };

    /**
     * Uploads the selected file to the backend API and fetches columns from the file.
     * Sets the columns and the fileId in state if the upload was successful.
     */
    const handleUpload = async () => {
        if (!selectedFile) {
            setError("Please select a file first.");
            return;
        }

        setIsLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
            const response = await fetch(
                `https://nyokug3qbj.execute-api.ca-central-1.amazonaws.com/dev/upload?org_id=${userData.user_data.org_id}`,
                {
                    method: "POST",
                    body: formData,
                }
            );

            console.log(response);
            if (!response.ok) {
                console.log(response);
                throw new Error("File upload failed.");
            }

            const data = await response.json();
            setFileId(data.fileId);

            const headersResponse = await fetch(
                `https://nyokug3qbj.execute-api.ca-central-1.amazonaws.com/dev/get-headers?fileId=${data.fileId}&orgId=${userData.user_data.org_id}`
            );
            console.log(headersResponse);
            if (!headersResponse.ok) {
                throw new Error("Failed to fetch column headers.");
            }

            const headersData = await headersResponse.json();
            setColumns(headersData.columns);
            setStatus("headers_fetched");
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    /**
     * Generates the report by sending a request to the backend using the file ID,
     * grouping instructions, report objective, and user data. If the report is successfully generated,
     * the component's status is set to `generation_started`
     */
    const handleGenerateReport = async () => {
        setIsLoading(true);
        setError(null);

        const aggregationInstructions = {
            groupBy: groupingColumns,
            aggregations: aggregationColumns.map((column) => ({
                column,
                function: aggregationFunctions[column] || "",
                label: aggregationLabels[column] || column,
            })),
        };

        try {
            const response = await fetch(
                "https://nyokug3qbj.execute-api.ca-central-1.amazonaws.com/dev/generate-report",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        fileId,
                        instructions: aggregationInstructions,
                        objective: reportObjective,
                        orgId: userData.user_data.org_id,
                        userId: userData.user_data.user_id,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Report generation failed.");
            }

            const data = await response.json();
            setReportId(data.reportId);
            setStatus("generation_started");
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

     /**
     * Exports the generated report as a .docx file.
     * Uses the docx library to convert markdown text to a downloadable docx document.
     */
    const handleExportDocx = async () => {
        const allParagraphs = [];
        const lines = reportText.split("\n");

        lines.forEach((line) => {
            const paragraphs = parseMarkdownLineToParagraphs(line);
            allParagraphs.push(...paragraphs);
        });

        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: allParagraphs,
                },
            ],
        });

        const blob = await Packer.toBlob(doc);
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `report_${new Date().toISOString()}.docx`;
        anchor.click();
        URL.revokeObjectURL(url);
    };

    /**
    * Parses each markdown line in the report to paragraphs, supporting headings and bold text.
    *
    * @param {string} line - The line from the report to parse.
    * @returns {Array<Paragraph>} - An array of paragraph objects.
    */
    const parseMarkdownLineToParagraphs = (line) => {
        if (line.startsWith("#### ")) {
            const headingText = line.replace(/^####\s*/, "");
            return [
                new Paragraph({
                    heading: HeadingLevel.HEADING_4,
                    children: parseBoldSegments(headingText),
                }),
            ];
        } else if (line.startsWith("### ")) {
            const headingText = line.replace(/^###\s*/, "");
            return [
                new Paragraph({
                    heading: HeadingLevel.HEADING_3,
                    children: parseBoldSegments(headingText),
                }),
            ];
        } else if (line.startsWith("## ")) {
            const headingText = line.replace(/^##\s*/, "");
            return [
                new Paragraph({
                    heading: HeadingLevel.HEADING_2,
                    children: parseBoldSegments(headingText),
                }),
            ];
        }
        return [new Paragraph({ children: parseBoldSegments(line) })];
    };

    /**
    * Parses bold text segments within a line of text.
    *
    * @param {string} line - The text line containing bold segments.
    * @returns {Array<TextRun>} - An array of TextRun objects representing formatted text.
    */
    const parseBoldSegments = (line) => {
        const result = [];
        const regex = /\*\*(.+?)\*\*/g;
        let lastIndex = 0;
        let match;
        while ((match = regex.exec(line)) !== null) {
            const startIndex = match.index;
            if (startIndex > lastIndex) {
                const normalText = line.slice(lastIndex, startIndex);
                if (normalText) {
                    result.push(new TextRun({ text: normalText }));
                }
            }
            const boldText = match[1];
            result.push(new TextRun({ text: boldText, bold: true }));
            lastIndex = regex.lastIndex;
        }
        if (lastIndex < line.length) {
            const leftover = line.slice(lastIndex);
            if (leftover) {
                result.push(new TextRun({ text: leftover }));
            }
        }
        if (result.length === 0) {
            result.push(new TextRun(""));
        }
        return result;
    };

      /**
     * Exports the report as a txt file.
     */
    const handleExportTxt = () => {
        const blob = new Blob([reportText], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `report_${new Date().toISOString()}.txt`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <div className="p-4 w-full h-full flex flex-col space-y-4 items-center justify-center">
            <Card className="w-1/2 min-h-1/3 flex flex-col space-y-4">
                <CardHeader>
                    <CardTitle className="text-3xl">Report Builder</CardTitle>
                </CardHeader>
                <CardContent>
                    {status === "ready" && (
                        <div className="flex items-center justify-center space-x-4">
                            <Input
                                type="file"
                                accept=".xlsx"
                                onChange={handleFileChange}
                                id="file-upload"
                                className="rounded-xl"
                            />
                            <Button onClick={handleUpload} disabled={!selectedFile || isLoading} className="rounded-xl">
                                Start Header Extraction
                            </Button>
                        </div>
                    )}

                    {status === "headers_fetched" && (
                        <div className="flex space-x-4">
                            <div className="w-1/2">
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Grouping Columns</CardTitle>
                                    </CardHeader>
                                    <CardContent className="space-y-2">
                                        {
                                            columns.map((column) => (
                                                <div key={column} className="flex items-center space-x-2">
                                                    <Checkbox
                                                        id={`group-${column}`}
                                                        checked={groupingColumns.includes(column)} // Directly check if column is in the array
                                                        onCheckedChange={(checked) => {
                                                            if (checked) {
                                                                console.log("Adding column to grouping:", column);
                                                                setGroupingColumns((prev) => [...prev, column]);
                                                            } else {
                                                                setGroupingColumns((prev) =>
                                                                    prev.filter((c) => c !== column)
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    <Label htmlFor={`group-${column}`} className="cursor-pointer">
                                                        {column}
                                                    </Label>
                                                </div>
                                            ))
                                        }
                                    </CardContent>
                                </Card>
                            </div>
                            <div className="w-1/2">
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Aggregation Columns</CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        {columns.map((column) => (
                                            <div key={column} className="flex items-center space-x-2">
                                                <Checkbox
                                                    id={`agg-${column}`}
                                                    checked={aggregationColumns.includes(column)} // Directly check if column is in the array
                                                    onCheckedChange={(checked) => {
                                                        if (checked) {
                                                            setAggregationColumns((prev) => [...prev, column]);
                                                        } else {
                                                            setAggregationColumns((prev) =>
                                                                prev.filter((c) => c !== column)
                                                            );
                                                            // Also, remove the aggregation function when unchecking
                                                            setAggregationFunctions((prevFunctions) => {
                                                                const updatedFunctions = { ...prevFunctions };
                                                                delete updatedFunctions[column];
                                                                return updatedFunctions;
                                                            });
                                                        }
                                                    }}
                                                />

                                                <Label
                                                    htmlFor={`agg-${column}`}
                                                    className="cursor-pointer"
                                                >
                                                    {column}
                                                </Label>
                                                <Select
                                                    value={aggregationFunctions[column] || ""}
                                                    onValueChange={(value) =>
                                                        handleAggregationFunctionChange(column, value)
                                                    }
                                                    disabled={!aggregationColumns.includes(column)}
                                                >
                                                    <SelectTrigger className="w-[180px]">
                                                        <SelectValue placeholder="Select Function" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="average">Average</SelectItem>
                                                        <SelectItem value="count">Count</SelectItem>
                                                        <SelectItem value="sum">Sum</SelectItem>
                                                        <SelectItem value="collect">Collect</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                                <Input
                                                    type="text"
                                                    placeholder="Label (optional)"
                                                    value={aggregationLabels[column] || ""}
                                                    onChange={(e) =>
                                                        handleAggregationLabelChange(column, e.target.value)
                                                    }
                                                    disabled={!aggregationColumns.includes(column)}
                                                />
                                            </div>
                                        ))}
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    )}

                    {status === "headers_fetched" && (
                        <div className="mt-4">
                            <Label htmlFor="report-objective">Report Objective:</Label>
                            <Textarea
                                id="report-objective"
                                value={reportObjective}
                                onChange={handleObjectiveChange}
                                className="mt-2"
                            />
                            <Button
                                onClick={handleGenerateReport}
                                disabled={isLoading}
                                className="mt-2"
                            >
                                Generate Report
                            </Button>
                        </div>
                    )}

                    {status === "generation_started" && (
                        <div className="flex flex-col items-center mt-4">
                            <p>Report generation started...</p>
                        </div>
                    )}

                    {status === "report_fetched" && (
                        <div className="mt-4">
                            <p>Download your report</p>
                            <div className="space-x-2">
                                <Button onClick={handleExportTxt}>Download as TXT</Button>
                                <Button onClick={handleExportDocx}>Download as DOCX</Button>
                            </div>
                        </div>
                    )}

                    {isLoading && status !== "generation_started" && <p>Loading...</p>}
                    {error && <p className="text-red-500">{error}</p>}
                </CardContent>
            </Card>
        </div>
    );
}

export default ReportBuilder;
